// Critical CSS
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  
  // Optimize paint performance
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

// Optimize images
img {
  content-visibility: auto;
  contain: layout style paint;
}

// Optimize hero section performance
.hero {
  contain: layout style paint;
  content-visibility: auto;
  
  .heroContent {
    contain: layout style;
    
    p {
      // Optimize text rendering
      text-rendering: optimizeSpeed;
      -webkit-font-smoothing: antialiased;
      
      // Prevent layout shifts
      display: block;
      height: auto;
      
      // Optimize paint
      will-change: transform;
      transform: translateZ(0);
    }
  }
}
